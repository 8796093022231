<template>
	<video-base :id="String(id)" category="サイバー関連サービス等解説コンテンツ" routerName="Basics" />
</template>

<script>
import VideoBase from "@/components/VideoBase";
export default {
	components: {
		VideoBase,
	},
  props: ["id"],
}
</script>